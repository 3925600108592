import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import { MdEmail } from "react-icons/md";
import { SiGooglescholar } from "react-icons/si";
import { Bio } from '../../data/constants';
import styled from 'styled-components';
import { FaGithub, FaLinkedin, FaMedium } from 'react-icons/fa';

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const SocialLinks = () => {
  return (
    <SocialMediaIcons>
          <SocialMediaIcon href={Bio.googleScholar} target="display"><SiGooglescholar title="Google Scholar"/></SocialMediaIcon>
          <SocialMediaIcon href={Bio.github} target="display"><FaGithub title="github" /></SocialMediaIcon>
          <SocialMediaIcon href={Bio.linkedin} target="display"><FaLinkedin title="Linkedin" /></SocialMediaIcon>
          <SocialMediaIcon href={Bio.insta} target="display"><InstagramIcon /></SocialMediaIcon>
          <SocialMediaIcon href={Bio.medium} target="display"><FaMedium title="Medium" /></SocialMediaIcon>
          <SocialMediaIcon href={Bio.email} target="display"><MdEmail title="Email" /></SocialMediaIcon>
    </SocialMediaIcons>
  )
}

export default SocialLinks